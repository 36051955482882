import type { CustomerType, CustomerSeriousness } from "../../@types/sharedSchema";

export const mappedCustomerTypes: Record<CustomerType, string> = {
  מוכר: "seller",
  קונה: "buyer",
  שוכר: "tenant",
  משכיר: "landlord",
  מתווך: "realtor",
  שמאי: "appraiser",
  'עו"ד': "attorney"
} as const;

export const mappedCustomerSeriousness: Record<CustomerSeriousness, string> = {
  "אין התאמה": "noMatch",
  "לא רציני": "notSerious",
  "לקוח חם": "hotCustomer",
  רציני: "serious"
} as const;
