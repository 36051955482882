import { PhoneNumber } from "libphonenumber-js/types";

export type BaseDocument = {
  id: string;
  path?: string;
  createdTime: Date;
  updatedTime: Date;
};

export const OnboardingSteps = [
  "phoneNumber",
  "userRolePick",
  "realEstateRolePick",
  "featuresPicker",
  "test"
] as const;
export type OnboardingStep = typeof OnboardingSteps[number];

export enum BulkMessagingTier {
  free = "free",
  paid = "paid"
}

export enum CrmType {
  realEstate = "realEstate",
  generic = "generic"
}

export const CallTranscriptionScenarios = ["callWithRealtor", "callWithSalesRep"] as const;
export type CallTranscriptionScenario = typeof CallTranscriptionScenarios[number];

export type User = Partial<BaseDocument> & {
  // email: string;
  photoURL: string;
  ownerFullName: string;
  ownerPhoneNumber?: string; // international format
  onboardingCompleted?: boolean;

  onboardingStep?: OnboardingStep;
  interestedWebotFeatures?: string[];
  realEstateRole?: string;
  ownerFirstName?: string;
  companyName?: string;

  virtualNumbers: string[];
  featureFlagsV2?: string[];

  myApiToken: string;
  bulkMessagingTier?: BulkMessagingTier;
  crmType?: CrmType;

  interestedInLocations?: ProcessedListingAddress[]; // relevant only for realtors
  interestedInCitiesDistinct?: string[]; // auto generated from interestedInLocations
  interestedInNeighborhoodsDistinct?: string[]; // auto generated from interestedInLocations

  wantToReceiveAlertsOnNewListingInMyInterestedLocations?: boolean; // default if not set is true
  wantToReceiveAlertsOnNewListingsThatMatchMyContacts?: boolean; // default if not set is true
  wantToReceiveAlertsOnOwnListingsThatMatchContactsInMyOrgs?: boolean; // default if not set is true
  wantToReceiveAlertsOnListingPriceReductions?: boolean; // default if not set is true

  additionalInternalCustomerData?: string;

  callTranscriptionScenario?: CallTranscriptionScenario;
  callTranscriptionScenarioAdditionalPromptNotes?: string;

  // TODO deprecate all below
  hasAccessToOtherUsers?: Array<string>;
  featureFlags?: {
    canViewNewContactsPageInMenu: boolean | null;
    hideDeleteLeadsButton: boolean | null;
    showAutoReplyBot: boolean | null;
    showCalendar: boolean | null;
  };
};

export type CallSummaryDailyAgregation = Partial<BaseDocument> & {
  ANSWER: number;
  NOANSWER: number;
  BUSY: number;
  CANCEL: number;
  IVR_HANGUP: number;
  CONGESTION: number;
};

export type Organization = Partial<BaseDocument> & {
  admins: string[];
  users: string[];
  logo: string;
  name: string;
};

export type BaseContact = Partial<BaseDocument> & {
  contactOrigin: string;
  isInSpamDatabase: boolean;
  lastBulkMessageReceivedTime: string;
  _metadata: any;
};

export type GenericContact = BaseContact & {
  callerPhoneNumber?: string; // this should be international format, but for some contacts, it is local format. Need to find why (there is a task) and backfill
  callerFirstName?: string;
  callerLastName?: string;
  additionalNotes?: string;
  isInSpamDatabase: boolean;
  optedOutOfBulkMessages: boolean;
  contactOrigin: string;
  contactOriginAdditionalInfo?: string;
  lastBulkMessageReceived?: {
    mediaLink: string;
    message: string;
    sentTime: string;
    sentTo: string;
  };
};

export const genericPropertyDetailsKeys = [
  "hasBars",
  "hasGarden",
  "hasParking",
  "hasStorage",
  "hasTerrace",
  "isRenovated",
  "hasElevator",
  "hasProtectedSpace",
  "hasAirConditioning",
  "hasHandicappedAccess",
  "hasHousingUnit"
] as const;

export type GenericPropertyDetails = {
  [K in typeof genericPropertyDetailsKeys[number]]: boolean;
};

export type RealEstateContact = GenericContact &
  Partial<GenericPropertyDetails> & {
    customerType: CustomerType | null;
    customerBudget: number;
    street: string;
    neighborhood: string;
    roomsMin: Room | null;
    roomsMax: Room | null;
    floorsMin: number | null;
    floorsMax: number | null;
    customerSeriousness: CustomerSeriousness | null;

    interestedInLocations?: ProcessedListingAddress[];
    interestedInCitiesDistinct: string[]; // auto generated from interestedInLocations
    interestedInNeighborhoodsDistinct: string[]; // auto generated from interestedInLocations

    interestedInPropertyTypes?: string[];

    AICustomerDetailsMetadata?: PhoneCallParsedOpenAiResponseCustomerDetails;
  };

/**
 * Customer seriousness:
 * 1. לא רציני = Not serious
 * 2. רציני = Serious
 * 3. אין התאמה = No match
 * 4. לקוח חם = Hot customer 🔥
 */
export const customerSeriousness = ["לא רציני", "רציני", "אין התאמה", "לקוח חם"] as const;
export type CustomerSeriousness = typeof customerSeriousness[number];

export const rooms = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6] as const;
export type Room = typeof rooms[number];

export const floors = [1, 2, 3, 4, 5, 6] as const;
export type Floor = typeof floors[number];

/**
 * Customer types:
 * 1. מוכר = Seller
 * 2. קונה = Buyer
 * 3. שוכר = Tenant
 * 4. משכיר = Landlord
 * 5. מתווך = Realtor
 * 6. עו"ד = Attorney
 * 7. שמאי = Appraiser
 */
export const customerTypes = ["מוכר", "קונה", "שוכר", "משכיר", "מתווך", 'עו"ד', "שמאי"] as const;
export type CustomerType = typeof customerTypes[number];

export const callEndResults = [
  "ANSWER",
  "NOANSWER",
  "BUSY",
  "CANCEL",
  "IVR_HANGUP",
  "CONGESTION"
] as const;
export type CallEndResult = typeof callEndResults[number];

export type CallSummary = Partial<BaseDocument> & {
  callerNumber: string; // local number of the customer. Usually, customers call our clients so this was called "callerNumber". But even if this is an outbound call from our client, the caller number will still be the number of the customer. caller === customer.
  callerNumberInternational: string;
  callerNumberWaFormat: string;
  customerName: string;
  duration: string;
  startAt: string;
  endAt: string;
  groupName: string;
  virtualNumber: string; // local virtual number of user
  realNumber: string; // local real number of user
  recordingLink: string;
  recordingLinkDirect: string;
  recordingLinkReplicatedToFirebaseUrl?: string; // The link to the recording that was replicated to Firebase Storage
  result: CallEndResult;
  status: string;
};

export type PayCallSettings = Partial<BaseDocument> & {
  customerIntroName: string;
  customerOrganizationName: string;
  customerUserName: string;
  noAnswerWhatsappMessage: string;
  noPhoneReplyCallToAction: string;
  noResponseBotWhatsappForwardingNumber: string;
  notesAboutCustomer: string; // internal
  number: string; // local virtual number
  ownerMails: string[];
  hasAiEnabled: boolean;
};

export type ProcessedListingAddress = {
  city: string;
  neighborhood: string;
  street: string;
  houseNumber?: number;
  floor?: number;
  coords?: {
    lat: number;
    lon: number;
  };
};

export const ListingOrigins = ["yad2", "webot"] as const;
export type ListingOrigin = typeof ListingOrigins[number];

export type ProcessedListingData = Partial<BaseDocument> &
  Partial<GenericPropertyDetails> & {
    publishedTime?: any; //todo remove
    lastUpdatedTime?: any; //todo remove
    listingPublishedTime: Date;
    listingUpdatedTime: Date;
    address: ProcessedListingAddress;
    buildingTotalFloors?: number;
    agencyName?: string;
    sellerName?: string;
    sellerPhoneNumber?: string;
    description?: string;
    coverImageUrl?: string;
    imageUrls?: string[];
    adType: AdType;
    price: number;
    propertyCondition?: string;
    roomsCount: number;
    squareMeter: number;
    listingOrigin: ListingOrigin;
    originalListingId: string;
    originalListingUrl: string;
    tags?: string[];

    propertyType: string;

    token?: string; // todo remove
    subcategoryId?: number; //todo remove

    parkingSpotsCount?: number; // additionalDetails.parkingSpacesCount
    isEnterDateFlexible?: boolean; // additionalDetails.isEnterDateFlexible
    hasBoiler?: boolean; // inProperty.includeBoiler
    isAssetExclusive?: boolean; // inProperty.isAssetExclusive
    includesFurniture?: boolean; // inProperty.includeFurniture
    isImmediateEntrance?: boolean; // inProperty.isImmediateEntrance
    entranceDate?: Date; // additionalDetails.entranceDate
    // originalSlimPartialData?: RawYad2ListingSlimPartialData; removing to save space
    // originalFullData?: RawYad2ListingFullItemData; removing to save space

    // TODO - find the appropriate backfill from Yad2
    sizeOfGardenM2?: number; // ?
    sizeOfTerraceM2?: number; // ?

    _geoloc?: {
      lat: number;
      lng: number;
    };
  };

export type Yad2Customer = {
  name: string;
  agencyName?: string;
  email?: string;
  id: number;
  phone: string;
  isVirtualPhoneNumber: boolean;
  brokerPhone: string;
};

export type Yad2Address = {
  city: {
    id: string;
    text: string;
  };
  neighborhood: {
    id: string;
    text: string;
  };
  street: {
    id: string;
    text: string;
  };
  house: {
    number: number;
    floor: number;
  };
  coords: {
    lat: number;
    lon: number;
  };
};
export type RawYad2ListingSlimPartialData = Partial<BaseDocument> & {
  dates: {
    updatedAt?: string; // only in some cases
    createdAt?: string; // only in some cases
  };
  address: Partial<Yad2Address>;
  customer: Partial<Yad2Customer>;
  metaData: {
    coverImage: string;
  };
  subcategoryId: number;
  adType: AdType;
  price: number;
  token: string;
  additionalDetails: {
    property: {
      text: string;
    };
    roomsCount: number;
    squareMeter: number;
  };
  tags: { name: string }[];
  orderId: number;
  priority: number;
};

export const adTypes = ["commercial", "private"] as const;
export type AdType = typeof adTypes[number];

export type RawYad2ListingFullItemData = Partial<BaseDocument> & {
  token: string;
  orderId: number;
  adNumber: number;
  adType: AdType;
  categoryId: number;
  subcategoryId: number;
  priority: number;
  statusId: number;
  price: number;
  additionalDetails: {
    balconiesCount: number;
    entranceDate: string; // format: YYYY-MM-DDT00:00:00
    squareMeter: number;
    squareMeterGarden: number;
    squareMeterBuild: number;
    roomsCount: number;
    parkingSpacesCount: number;
    buildingTopFloor: number;
    isEnterDateFlexible: boolean;
    propertyGroup: {
      id: number;
      text: string;
      textEng: string;
    };
    property: {
      id: number;
      text: string;
      textEng: string;
    };
    propertyCondition: {
      id: number;
      text: string;
    };
  };
  inProperty: {
    isKitchenKosher: boolean;
    isAssetExclusive: boolean;
    includeAirconditioner: boolean;
    includeBalcony: boolean;
    includeBars: boolean;
    includeBoiler: boolean;
    includeFurniture: boolean;
    includeElevator: boolean;
    includeParking: boolean;
    includeSecurityRoom: boolean;
    includeWarehouse: boolean;
    isHandicapped: boolean;
    includeRavBariach: boolean;
    includeTadiran: boolean;
    includeTornado: boolean;
    isImmediateEntrance: boolean;
    isRenovated: boolean;
    // TODO - more here?
  };
  searchText: string;
  customer: Yad2Customer;
  address: Yad2Address;
  metaData: {
    coverImage: string;
    images: string[];
    description: string;
  };
  dates: {
    updatedAt: string;
    createdAt: string;
    endsAt: string;
    rebouncedAt: string;
  };
  abovePrice: string;
  tags: {
    name: string;
    id: number;
    priority: number;
  }[];
  //...
};

export type SingleListingItemScrappingResponse = {
  props: {
    pageProps: {
      dehydratedState: {
        queries: [
          {
            queryKey: string[];
            state: {
              data: RawYad2ListingFullItemData;
            };
          }
        ];
      };
    };
  };
};

export type ListingsItemsPageScrappingResponse = {
  url: string;
  props: {
    pageProps: {
      statusCode: number;
      searchApiQuery: {
        page: number;
      };
      feed: {
        private: RawYad2ListingSlimPartialData[];
        agency: RawYad2ListingSlimPartialData[];
        yad1: RawYad2ListingSlimPartialData[];
        platinum: RawYad2ListingSlimPartialData[];
        kingOfTheHar: any[];
        trio: any[];
        booster: any[];
        leadingBroker: any[];
        pagination: any;
      };
    };
  };
};

export type GenericScrappingIDE_Response = {
  url: string;
  stringRawData: string;
};

export type ScrappingJobState = {
  currentState: "idle" | "scrappingPages" | "scrappingSingleListings";
  currentCollectionId: string;
  listingsPagesFetched: number;
  totalUniqueListingsFetchedInPages: number;
  listingsToRefetchDueToMissingImages: number;
  newListingsFetchedInPages: number;
  newListingsCreated: number;
};

export type PhoneCallParsedOpenAiResponseCustomerDetails = {
  callerFirstName?: string;
  callerLastName?: string;
  additionalNotes?: string;

  // Only for real estate calls
  customerBudget?: number;
  neighborhood?: string;
  interestedInLocations?: ProcessedListingAddress[];
  street?: string;
  roomsMin?: Room;
  roomsMax?: Room;
  customerType?: CustomerType;
};

export type PhoneCallParsedOpenAiResponse = {
  customerDetails: PhoneCallParsedOpenAiResponseCustomerDetails;
  callSummary: string;
  customerSummary: string;
  speakerNames: {
    original: string;
    actual: string;
  }[];
};

export type CallTranscriptAndSummary = Partial<BaseDocument> & {
  AI: PhoneCallParsedOpenAiResponse;
  jobId: string;
  textTranscription: string;
  splitTranscription: {
    speaker: string;
    sentence: string;
  }[];
  summary: string;
};

export type FavoriteListingsOfContact = Partial<BaseDocument> & {
  contactId: string; // the contact international phone number
  realtorId: string; // the user mail
  listingIds: string[];
  listingIdsUserLiked: string[];
  listingIdsUserDisliked: string[];
  listingIdsWithPicturesHidden: string[];
};

export type ListingsRealtorBlacklist = Partial<BaseDocument> & {
  realtorId: string;
  listingIds: string[];
};

export type CalendarSettings = Partial<BaseDocument> & {
  admin: string;
  owners: string[];
  calendarName: string;
  ownerFullName: string;
  ownerPhoneNumberInternational: string;
  reminderHourBeforeEvent: boolean;
  reminderOnMorningDayBefore: boolean;
  reminderOnEveningBeforeEvent: boolean;
  reminderOnMorningOfEvent: boolean;
};

export type CalendarEventAttendee = {
  firstName?: string;
  lastName?: string;
  phoneNumberObj?: PhoneNumber;
  phoneNumberInternational?: string;
};

export type CalendarEvent = Partial<BaseDocument> & {
  title: string;
  description?: string;
  textColor: string;
  backgroundColor?: string; // the background color is used in the list view, using the same as text color
  allDay?: boolean;
  start: Date;
  end: Date;
  attendees?: CalendarEventAttendee[];
};

export type Caller = Partial<BaseDocument> & {
  callerNumberInternational?: string;
};

export type googleOauthToken = Partial<BaseDocument> & {
  userId: string;

  // for more details about fields, see import { Credentials } from 'google-auth-library';
  refresh_token?: string | null;
  expiry_date?: number | null;
  access_token?: string | null;
  token_type?: string | null;
  id_token?: string | null;
  scope?: string;
};

export type cardcomAccountSnapshot = Partial<BaseDocument> & {
  accountId: number;
  customerName: string;
  identificationIdNumber: string;
  localMobilePhoneNumber: string;
  email: string;

  hadAtLeastOneRecuringPaymentInHistory?: boolean;
  isRecurringPaymentActive?: boolean;

  paymentsHistory?: {
    date: Date;
    amount: number;
    amountBeforeTax: number;
  }[];

  failedPaymentsHistory?: {
    date: Date;
    amount: number;
    amountBeforeTax: number;
  }[];

  lastPaymentDate?: Date;
  paymentsHistoryTotalPaid?: number;

  // todo remove
  lastPaymentsFromReport?: {
    date: Date;
    amount: number;
    amountBeforeTax: number;
  }[];

  // todo remove
  lastPaymentsFromReportTotal?: number;

  // todo remove
  lastPayments?: {
    date: Date;
    amount: number;
  }[];

  // todo remove
  lastPaymentsTotal?: number;
};
export namespace REST_schema {
  export type orgAdminStatsRequestSchema = {
    dateFrom: string;
    organizationId?: string;
  };

  export type _orgAdminStatsResponse_callsDataSchema = {
    phoneNumber: string;
    sumAnswered: number;
    sumNotAnswered: number;
    totalCalls: number;
    newLeads: number;
  };

  export type _orgAdminStatsResponse_userDataSchema = {
    userMail: string;
    userFullName: string;
    userPhotoURL: string;
    callsData: _orgAdminStatsResponse_callsDataSchema[];
    virtualNumbersCount: number;
    sumAnswered: number;
    sumNotAnswered: number;
    totalCalls: number;
    newLeadsFromVirtualNumbers: number;
    totalContactsCount: number;
    newLeadsFromWhatsappBots: number;
    calendarMeetingsCount: number;
    bulkMessagesSentCount: number;
  };

  export type orgAdminStatsResponseSchema = {
    userData: (_orgAdminStatsResponse_userDataSchema | null)[];
    teamMembersCount: number;
    totalWhatsappBotsCount: number;
    totalVirtualNumbersCount: number;
    totalNewLeadsFromVirtualNumbers: number;
    totalNewLeadsFromWhatsappBots: number;
    totalBulkMessagesSentCount: number;
    totalCalendarMeetingsCount: number;
    totalNotAnsweredCalls: number;
    totalCalls: number;
  };

  export type MarkNoSpamContactsResponseSchema = {
    allContactsCount: number;
    markedSpamContacts: string[];
    skippedContactsCount: number;
    markedSpamContactsCount: number;
    unmarkedSpamContactsCount: number;
  };

  export namespace Webhooks {
    export type updateContactSchema = {
      phoneNumberInternational: string;
      email?: string;
      fullName?: string;
    };
    export const webhookTypes = [
      "paycall-merkazyia-webhooks",
      "update-contact",
      "publish-webot-lead-to-kala-crm",
      "new-sale-event-from-cardcom"
    ] as const;
    export type WebhookType = typeof webhookTypes[number];
  }

  export type RealtorsWithContactsForListing = {
    realtorName?: string;
    realtorMail: string;
    realtorPhoneNumberInternational?: string;
    realtorAvatar?: string;
    partialContactDetails: RealEstateContactWithConfidence[];
  };

  export type MatchConfidence = { confidencePercent: number }; // 0-100
  export type RealEstateContactWithConfidence = Partial<RealEstateContact> &
    MatchConfidence & { realtorId: string };

  export type RelevantContactsForListingResponse = {
    fromMyContacts: RealEstateContactWithConfidence[];
    fromOrgContacts: RealtorsWithContactsForListing[];
  };
}
